import React, {Component} from "react";
import Header from "../header/header";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import {Link} from "react-router-dom";

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentWallet: 0
        }
    }

    componentDidMount() {
        this.getCurrentWallet();
    }

    async getCurrentWallet () {
        const sendData = {
            student_id: this.props.userData.student_id
        }

        console.log('entered')

        await axios.post(`${serverLink}payment/current_wallet`, sendData)
            .then(result => {
                console.log('entered entered')
                const data = result.data;
                if (data.length > 0) {
                    let outstanding = parseInt(data[0].OutStandingAmount);
                    console.log(outstanding)
                    this.setState({currentWallet: outstanding});
                }
            })
            .catch(error => {
                console.log('ERROR FETCHING CURRENT_WALLET', error)
            })
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    render() {
        return (
            <div>
                <Header />
                <main id="content" role="main">
                    <div className="container content-space-t-3 content-space-t-lg-4">
                        <div className="text-center mb-7">
                            <h1 className="display-2">Page Not Found!!!</h1>
                        </div>

                    </div>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(NotFound);
