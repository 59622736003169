import { shortCode } from "../resources/url";

export const setLogInDetails = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_LOGIN_DETAILS`,
        payload: p,
    };
};

export const setWalletData = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_WALLET_DATA`,
        payload: p,
    };
};

export const setCourseFeesData = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_COURSE_FEES_DATA`,
        payload: p,
    };
};

export const setOtherFeesData = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_OTHER_FEES_DATA`,
        payload: p,
    };
};

export const setMinAmountAllowData = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_MIN_AMOUNT_ALLOW_DATA`,
        payload: p,
    };
};

export const setScholarshipData = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_SCHOLARSHIP_DATA`,
        payload: p,
    };
};

export const setPaymentHistoryData = (p) => {
    return {
        type: `SET_${shortCode}_PAYMENT_HISTORY_DATA`,
        payload: p,
    };
};

export const setMedicalsData = (p) => {
    return {
        type: `SET_${shortCode}_MEDICALS_DATA`,
        payload: p,
    };
};