import React, {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import Header from "../header/header";
import {Link} from "react-router-dom";

class PaymentHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            history: [],
            isLoading: true
        }
    }

    componentDidMount() {
        this.getPaymentHistory();
    }

    async getPaymentHistory () {
        const sendData = {
            student_id: this.props.userData.student_id
        }

        if (this.props.paymentHistory.length > 0) {
            this.setState({history: this.props.paymentHistory});
            this.setState({isLoading: false});
        }
        else {
            await axios.post(`${serverLink}payment/payment_history`, sendData)
                .then(result => {
                    const data = result.data;
                    this.setState({isLoading: false});
                    if (data.length > 0) {
                        this.setState({history: data});
                    }
                })
                .catch(error => {
                    console.log('ERROR FETCHING CURRENT_WALLET', error)
                })
        }

    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
        } else {
            return '--'
        }
    };

    render() {
        const isDate = function(date) {
            return Date.parse(date)
        }
        return (
            <div>
                <Header />
                <main id="content" role="main">
                    <div id="openingsSection" className="container content-space-t-2 content-space-lg-3 content-space-b-1">
                        <div className="text-center mb-7">
                            <h1 className="display-6">Payment History</h1>
                        </div>
                        <div className="d-grid gap-3">
                            {
                                this.state.isLoading ?
                                    <div className="js-swiper-preloader swiper-preloader">
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div> :
                                    this.state.history.length > 0 ?
                                        this.state.history.map((data, index) => {
                                            return <Link key={index} className="card" to={`/payment-receipt/${data.PaymentID}`}>
                                                    <div className="card-body">
                                                        <div className="row">

                                                            <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                                                                <span className="text-body">{this.currencyConverter(data.AmountPaid)}</span>
                                                            </div>

                                                            <div className="col-sm-5 col-md-4 mb-2 mb-sm-0">
                                                                <span className="text-body">{isDate(data.InsertDate) ? this.formatDate(data.InsertDate) : data.InsertDate }</span>
                                                            </div>

                                                            <div className="col-sm-2 text-sm-end">
                                                                <span className="link link-pointer">Print</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                        })
                                        : <div className="alert alert-info">No Previous Payment History Found</div>
                            }

                        </div>
                    </div>
                </main>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails,
        paymentHistory: state.paymentHistoryData
    };
};

export default connect(mapStateToProps, null)(PaymentHistory);
