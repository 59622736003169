import React, {Component} from "react";
import {Route, Routes, HashRouter as Router} from "react-router-dom";
import Login from "../component/login/login";
import Dashboard from "../component/dashboard/dashboard";
import NotFound from "../component/notfound/notfound";
import PaymentHistory from "../component/history/paymenthistory";
import PayNow from "../component/pay/paynow";
import PaymentReceipt from "../component/paymentreceipt/paymentreceipt";


class PageRoutes extends Component {
    render() {
        return (
            <Router hashType="slash">
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route exact path="/history" element={<PaymentHistory />} />
                    <Route exact path="/pay-now" element={<PayNow />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/payment-receipt/:id" element={<PaymentReceipt />} />
                    <Route exact path="*" element={<NotFound />} />
                </Routes>
            </Router>
        )
    }
}
export default PageRoutes;
