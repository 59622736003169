import React, {Component} from "react";
import axios from "axios";
import {serverLink} from "../../resources/url";
import {setLogInDetails} from "../../actions/setpersonaldetail";
import {connect} from "react-redux";
import Logo from "../images/logo.png"

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverLink: serverLink + 'login/payment_login',
            userType: "",
            userID: "",
            emailAddress: "",
            errorUserID: false,
            errorEmailAddress: false,
            message: "",
            isProcessing: false
        }
    }

    onEdit = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    onUserTypeChange = (e) => {
        this.setState({userType: e.target.value})
    }

    onLogin = (e) => {
        e.preventDefault();
        this.setState({
            isProcessing: true
        });

        this.setState({errorUserID: false, errorEmailAddress: false, message: ""});
        const sendData = {
            user_type: this.state.userType,
            user_id: this.state.userID,
            email_address: this.state.emailAddress
        }

        if (sendData.user_id === '') {
            this.setState({errorUserID: true, isProcessing: false});
            return false;
        }
        if (sendData.email_address === '') {
            this.setState({errorEmailAddress: true, isProcessing: false});
            return false;
        }

        axios.post(this.state.serverLink, sendData)
            .then(response => {
                const result = response.data;
                const message = result.message;
                if (message === 'success') {
                    this.setState({
                        message: <div className="mt-1 alert alert-success">Login successfully. Redirecting, please wait...</div>,
                        isProcessing: false
                    });
                    this.props.setOnLogInDetails({...result, userType: this.state.userType});
                } else {
                    let error_message = "";
                    if (message === 'invalid_id')
                        error_message = 'Invalid ID. Please check your ID and try again!';
                    else if (message === 'invalid_email')
                        error_message = 'Invalid Email. Please check your Email Address and try again!';
                    else
                        error_message = 'Invalid Login Credential. Please check your Login Credential and try again!';

                    this.setState({message: <div className="mt-1 alert alert-danger">{error_message}</div>, isProcessing: false});
                }
            })
            .catch(error => {
                this.setState({
                    isProcessing: false
                })
                console.log(error)
            });
    }

    render() {
        return (
            <main id="content" role="main" className="flex-grow-1 overflow-hidden">
                <div className="container content-space-t-1 content-space-b-2 align-items-center">
                    <div className="mx-lg-auto" style={{maxWidth: "55rem"}}>
                        <div className="d-flex justify-content-center align-items-center flex-column min-vh-lg-100">
                            <h3>Baze University Payment Portal</h3>
                            <header id="header" className="navbar navbar-height navbar-light mb-3">
                                <div className="container">
                                    <img className="navbar-brand-logo" src={Logo}
                                         alt="Baze University Logo"/>
                                </div>
                            </header>

                            <div className="position-relative">
                                <div className="card card-shadow card-login">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <form className="js-validate needs-validation" onSubmit={this.onLogin}>
                                                    <div className="text-center">
                                                        <div className="mb-5">
                                                            <h3 className="card-title">Login here</h3>
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <label className="form-label">User Type</label>
                                                        <select
                                                            className="form-select"
                                                            name="userType"
                                                            id="userType"
                                                            onChange={this.onUserTypeChange}
                                                        >
                                                            <option value="">Select Option</option>
                                                            <option value="returning">Returning Student</option>
                                                            <option value="new">New Student</option>
                                                        </select>
                                                    </div>

                                                    {
                                                        this.state.userType !== "" ?
                                                            <>
                                                                <div className="mb-4">
                                                                    <label
                                                                        className="form-label">Your {this.state.userType === 'new' ? 'Application ID' : 'Student ID'}</label>
                                                                    <input type="text"
                                                                           className="form-control form-control-lg"
                                                                           name="userID"
                                                                           defaultValue={this.state.userID}
                                                                           onChange={this.onEdit}
                                                                           placeholder={this.state.userType === 'new' ? 'Your Application ID' : 'Your Student ID'}
                                                                    />
                                                                    {
                                                                        this.state.errorUserID ?
                                                                            <span className="alert-danger">Please enter a valid {this.state.userType === 'new' ? 'Application ID' : 'Student ID'}.</span> : ''
                                                                    }
                                                                </div>
                                                                <div className="mb-4">
                                                                    <label className="form-label"
                                                                           tabIndex="0">Email Address</label>

                                                                    <div className="input-group-merge">
                                                                        <input type="email"
                                                                               className="form-control form-control-lg"
                                                                               name="emailAddress"
                                                                               defaultValue={this.state.emailAddress}
                                                                               onChange={this.onEdit}
                                                                               placeholder=
                                                                                   {
                                                                                       this.state.userType === 'new' ?
                                                                                           'Enter your registered email address' : 'Enter your university email address'
                                                                                   }
                                                                        />

                                                                        {
                                                                            this.state.errorEmailAddress ?
                                                                                <span className="alert-danger">Please enter a valid email address.</span> : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="d-grid gap-4">
                                                                    <button type="submit"
                                                                            className="btn btn-primary btn-lg">Sign in
                                                                    </button>
                                                                </div>


                                                                {
                                                                    this.state.isProcessing ? <div className="mt-1 alert alert-info">Authenticating your input. Please wait...</div> : ''
                                                                }

                                                                {this.state.message}
                                                            </>
                                                            : ''
                                                    }
                                                </form>
                                            </div>
                                        </div>

                                        <div
                                            className="col-md-12 d-md-flex justify-content-center flex-column bg-soft-primary p-8 p-md-5">
                                            <h5 className="mb-4">Instruction</h5>

                                            {
                                                this.state.userType === 'new' ?
                                                    <ul className="list-checked list-checked-primary list-py-2">
                                                        <li className="list-checked-item">Use the last four digit of your
                                                            application as the Application ID
                                                        </li>
                                                        <li className="list-checked-item">Use your registered email
                                                            address
                                                        </li>
                                                    </ul> : ''
                                            }

                                            {
                                                this.state.userType === 'returning' ?
                                                    <ul className="list-checked list-checked-primary list-py-2">
                                                        <li className="list-checked-item">Enter your Student ID</li>
                                                        <li className="list-checked-item">Enter your University Email
                                                            Address
                                                        </li>
                                                    </ul> : ''
                                            }

                                            {
                                                this.state.userType === '' ?
                                                    <ul className="list-checked list-checked-primary list-py-2">
                                                        <li className="list-checked-item">Select the user type to see the
                                                            instruction(s)
                                                        </li>
                                                    </ul> : ''
                                            }

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLogInDetails: (p) => {
            dispatch(setLogInDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
