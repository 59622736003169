import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../header/header";
import axios from "axios";
import { serverLink, serverStatus } from "../../resources/url";
import { PaystackButton } from "react-paystack";
import { Link } from "react-router-dom";
import ProcessingLoader from "../loading/loading";
import { setWalletData } from "../../actions/setpersonaldetail";

class PayNow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            cartItems: [],
            tuitionFee: 0,
            otherFees: [],
            currentWallet: 0,
            minimumAmountAllowForRegistration: 0,
            processingMessage: "",
            processingMessage2: "",
            isProcessing: false,
            isPaymentChecker: false,
            processingPayment: false,
            amountToPay: 0,
            payStackConfig: '',
            paymentReady: false,
            amountExpected: 0,
            paymentEntryID: 0,
            scholarship: [],
            isPaymentComplete: false,
            isPaymentCompleteID: "",
            paymentAmount: 0,
            schoolSemester: "",
            CourseClass: "",
            radioChecked: false
        }
    }

    componentDidMount() {
        this.getFees();
    }

    async getWalletData() {
        const sendData = {
            student_id: this.props.userData.student_id,
            course_id: this.props.userData.course_id,
            student_level: this.props.userData.student_level,
            student_semester: this.props.userData.student_semester,
            app_type: this.props.userData.app_type
        }

        await axios.post(`${serverLink}payment/current_wallet`, sendData)
            .then(result => {
                this.setState({ isLoading: false })
                const data = result.data;
                if (data.length > 0) {
                    let outstanding = parseInt(data[0].OutStandingAmount);
                    this.setState({ currentWallet: outstanding });
                    this.props.setOnWalletDataDetails(parseInt(data[0].OutStandingAmount));
                }
                else {
                    this.props.setOnWalletDataDetails(0);
                }
            })
            .catch(error => {
                console.log('ERROR FETCHING CURRENT_WALLET', error)
            })
    }

    async getFees() {
        const sendData = {
            student_id: this.props.userData.student_id,
            course_id: this.props.userData.course_id,
            student_level: this.props.userData.student_level,
            student_semester: this.props.userData.student_semester,
            app_type: this.props.userData.app_type
        }

        if (this.props.tuitionFee > 0) {
            this.setState({
                tuitionFee: this.props.tuitionFee
            })
        }
        else {

            await axios.post(`${serverLink}payment/course_fee`, sendData)
                .then(result => {
                    const tuition = parseInt(result.data.TuitionAmount);
                    if (tuition > 0) {
                        this.setState({
                            tuitionFee: tuition
                        })
                    }
                })
                .catch(error => {
                    console.log('ERROR SENDING TUITION FEE REQUEST', error)
                });
        }

        if (this.props.wallet !== '') {
            this.setState({ currentWallet: this.props.wallet });
            let current_cart_items = this.state.cartItems;
            if (this.props.wallet > 0) {
                const sendData = {
                    item_name: 'Outstanding',
                    item_amount: this.props.wallet
                }
                current_cart_items.push(sendData)
                this.setState({
                    cartItems: current_cart_items
                })
            }

        }
        else {
            await axios.post(`${serverLink}payment/current_wallet`, sendData)
                .then(result => {
                    const data = result.data;
                    if (data.length > 0) {
                        let outstanding = parseInt(data[0].OutStandingAmount);
                        this.setState({ currentWallet: outstanding });

                        let current_cart_items = this.state.cartItems;
                        if (this.props.wallet > 0) {
                            const sendData = {
                                item_name: 'Outstanding',
                                item_amount: this.props.wallet
                            }
                            current_cart_items.push(sendData)
                            this.setState({
                                cartItems: current_cart_items
                            })
                        }

                    }
                })
                .catch(error => {
                    console.log('ERROR FETCHING CURRENT_WALLET', error)
                })
        }

        if (this.props.otherFees.length > 0) {
            this.setState({ otherFees: this.props.otherFees });
            this.props.otherFees.map(item => {
                if (this.props.medicals.length < 1){
                    if (item.IsRequired === 1) {
                        let current_cart_items = this.state.cartItems;
                        const sendData = {
                            item_name: item.Title,
                            item_amount: item.Amount
                        }
                        current_cart_items.push(sendData)

                        this.setState({
                            cartItems: current_cart_items
                        })
                    }
                }
            })
        }
        else {
            await axios.post(`${serverLink}payment/other_fees`, sendData)
                .then(result => {
                    const data = result.data;

                    this.setState({ otherFees: data });

                    if (data.length > 0) {
                        data.map(item => {
                            if (this.props.medicals.length < 1) {
                                if (item.IsRequired === 1) {
                                    let current_cart_items = this.state.cartItems;
                                    const sendData = {
                                        item_name: item.Title,
                                        item_amount: item.Amount
                                    }
                                    current_cart_items.push(sendData)

                                    this.setState({
                                        cartItems: current_cart_items
                                    })
                                }
                            }
                        })
                    }

                })
                .catch(error => {
                    console.log('ERROR FETCHING CURRENT_WALLET', error)
                })
        }

        if (this.props.minimumAmountAllowForRegistration > 0) {
            this.setState({ minimumAmountAllowForRegistration: this.props.minimumAmountAllowForRegistration });
        }
        else {
            await axios.get(`${serverLink}payment/minimum_amount_allow_for_registration`)
                .then(result => {
                    const data = result.data;
                    this.setState({ minimumAmountAllowForRegistration: data.MinAmountAllow });
                })
                .catch(error => {
                    console.log('ERROR FETCHING CURRENT_WALLET', error)
                })
        }

        if (this.props.scholarship.length > 0) {
            this.setState({ scholarship: this.props.scholarship });
            this.setState({ isLoading: false });
        }
        else {
            await axios.post(`${serverLink}payment/get_student_scholarship`, sendData)
                .then(result => {
                    const data = result.data;
                    if (data.message === 'success') {
                        this.setState({ scholarship: data.data })
                    }
                    this.setState({ isLoading: false });
                })
                .catch(error => {
                    console.log('ERROR FETCHING CURRENT_WALLET', error)
                })
        }

        ///////////////Feb, 10th 2023///////////////////////////////////
        await axios.post(`${serverLink}payment/get_course_class`, sendData)
            .then(result => {
                const data = result.data;
                if (data.message === 'success') {
                    this.setState({ CourseClass: data.data[0]?.DurationType })
                }
                this.setState({ isLoading: false });
            })
            .catch(error => {
                console.log('ERROR FETCHING CURRENT_WALLET', error)
            })

        this.setState({ isLoading: false });
    }


    handleTuitionChange = (e) => {
        if (!e.target.checked || this.state.radioChecked) {
            return false;
        }
        let sendData;
        let item_name = e.target.id;
        let item_amount = parseFloat(e.target.value);

        let currentItem = this.state.cartItems;
        const scholarship = this.state.scholarship;

        this.setState({
            isProcessing: false,
            isPaymentChecker: false,
            paymentReady: false,
            radioChecked: true
        });

        if (scholarship.length > 0) {
            if (scholarship[0].TuitionPercentage > 0) {
                item_name += ` (${scholarship[0].ScholarshipName})`;
                item_amount -= (item_amount * scholarship[0].TuitionPercentage) / 100;
            }
        }

        sendData = {
            item_name: item_name,
            item_amount: item_amount
        }

        let filteredItem = currentItem.filter(item => item.item_name.includes('Session Tuition'));
        let latestItem = filteredItem.filter(item => item.item_name.includes('Semester Tuition'));

        latestItem.push(sendData);

        // if (this.props.wallet > 0) {
        //     let sendData2 = {
        //         item_name: 'Outstanding',
        //         item_amount: this.props.wallet
        //     }
        //
        //     latestItem.push(sendData2)
        //
        // }
        this.setState({ cartItems: [...latestItem, ...this.state.cartItems.filter(e=> e.item_name !== 'Session Tuition' && e.item_name !== 'Semester Tuition')] })
    }

    handleOtherFeeChange = (e) => {
        let currentItem = this.state.cartItems;
        let item_name = e.target.id;
        let item_amount = parseFloat(e.target.value);
        const scholarship = this.state.scholarship;

        this.setState({
            isProcessing: false,
            isPaymentChecker: false,
            paymentReady: false
        });

        if (scholarship.length > 0) {
            if (item_name.includes('Hostel')) {
                if (scholarship[0].HostelPercentage > 0) {
                    item_name += ` (${scholarship[0].ScholarshipName})`;
                    item_amount -= (item_amount * scholarship[0].HostelPercentage) / 100;
                }
            }

            if (item_name.includes('Feeding')) {
                if (scholarship[0].FeedingPercentage > 0) {
                    item_name += ` (${scholarship[0].ScholarshipName})`;
                    item_amount -= (item_amount * scholarship[0].FeedingPercentage) / 100;
                }
            }

        }

        const sendData = {
            item_name: item_name,
            item_amount: item_amount
        }

        if (e.target.checked === false) {
            let filteredItem = currentItem.filter(item => item.item_name !== item_name);
            this.setState({ cartItems: filteredItem })
        }
        else {
            currentItem.push(sendData);
            this.setState({ cartItems: currentItem })
        }
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    payNow = () => {
        const amount = document.getElementById('amount_to_pay').value;
        const amount_to_pay = parseFloat(amount);
        const cart_items = this.state.cartItems;
        let amount_expected = parseFloat(document.getElementById('TotalAmountDue').value);
console.log(cart_items, "cart")
console.log(amount_to_pay, "amount_to_pay")
console.log(amount, "amount")
        if (cart_items.length < 1) {
            this.setState({
                isProcessing: true,
                processingMessage: "Please select the item you want to pay first",
            });
            return false;
        }

        this.setState({
            isProcessing: true,
            processingMessage: "Processing your payment. Please wait...",
        });

        this.state.amountToPay = amount_to_pay;

        if (amount === "") {
            this.setState({
                isProcessing: true,
                processingMessage: "Please enter the amount you wish to pay"
            });
            return false
        }
        this.setState({
            paymentAmount: parseFloat(amount)
        })

        this.state.amountExpected = amount_expected;

        this.makePaymentToPayStack();

    }

    payFromWallet = async () => {
        let walletTotal = 0;
        const cart_items = this.state.cartItems;

        this.setState({
            isProcessing: true,
            processingMessage: "Processing your payment. Please wait!",
            processingMessage2: "please wait"
        });

        for (let item = 0; item < cart_items.length; item++) {
            walletTotal += parseFloat(cart_items[item].item_amount)
        }

        const sendData = {
            reference: (new Date()).getTime().toString(),
            payment_id: (new Date()).getTime().toString() + '-wallet',
            student_id: this.props.userData.student_id,
            student_name: this.props.userData.student_name,
            student_first: this.props.userData.student_first,
            student_middle: this.props.userData.student_middle,
            student_surname: this.props.userData.student_surname,
            student_level: this.props.userData.student_level,
            student_semester: this.props.userData.student_semester,
            amount_paid: walletTotal,
            app_type: this.props.userData.app_type === 'student' ? 'Returning Student' : 'New Student',
            school_semester: this.props.userData.app_type === 'student' ? this.state.schoolSemester : this.props.userData.admission_semester,
            transaction_date: this.formatDate(new Date()),
            amount_expected: walletTotal,
            payment_method: 'Wallet',
            description: 'Payment from Wallet',
            outstanding: this.state.currentWallet + walletTotal
        }

        await axios.post(`${serverLink}payment/post_payment`, sendData)
            .then(result => {
                if (result.data.message === 'failed') {
                    this.setState({
                        processingMessage: "Something went wrong completing the transaction. Please send the payment receipt to accounts@bazeuniversity.edu.ng. (PS You can find the payment receipt in your email address)"
                    })
                    return false
                }
            })
            .catch(error => {
                console.log('ERROR POSTING PAYMENT')
            });


        for (let item = 0; item < cart_items.length; item++) {
            const sendDataItem = {
                description: cart_items[item].item_name,
                amount: cart_items[item].item_amount,
                reference: sendData.reference,
                student_id: sendData.student_id,
                student_name: sendData.student_name,
                student_first: sendData.student_first,
                student_middle: sendData.student_middle,
                student_surname: sendData.student_surname,
                student_level: sendData.student_level,
                student_semester: sendData.student_semester,
                transaction_date: this.formatDate(new Date()),
                school_semester: this.props.userData.app_type === 'student' ? this.state.schoolSemester : this.props.userData.admission_semester,
            }

            await axios.post(`${serverLink}payment/post_payment_item`, sendDataItem)
                .then(result => {
                    if (result.data.message !== 'success') {
                        this.setState({
                            processingMessage: "Something went wrong completing the transaction. Please send the payment receipt to accounts@bazeuniversity.edu.ng. (PS You can find the payment receipt in your email address)"
                        })
                    }
                })
                .catch(error => {
                    console.log('ERROR POSTING PAYMENT')
                });

        }
        this.getWalletData()

        this.setState({
            processingMessage2: "",
            processingMessage: "Payment posted successful. Please wait while the system redirect you to the receipt page"
        });

        setTimeout(() => {
            window.location.href = `#/payment-receipt/${sendData.reference}`
        }, 500);

    }

    handleOnPaymentChange = (e) => {
        this.setState({
            isPaymentChecker: false,
            isProcessing: false,
            paymentReady: false
        });

        this.payNow();

    }

    makePaymentToPayStack() {
        const amount_expected = this.state.amountExpected;
        const amount_paid = this.state.amountToPay;
        const Live_key = "pk_live_12317e78152cbe1f4fa00ce79b99f497c8e2b989";
        const Test_key = "pk_test_4afdc4ff65ce2328e5dd6a678f78103e0fe32c42";
        let outstanding;

        outstanding = amount_expected - amount_paid

        this.setState({
            isProcessing: false,
            isPaymentComplete: false,
            isPaymentCompleteID: ""
        })
        const cart_ = this.state.cartItems;
        let dynamicCartItems = [];
        cart_.map((item) => {
            dynamicCartItems.push(
                {
                    display_name: "Cart Item",
                    variable_name: item.item_name,
                    value: item.item_amount
                }
            )
        })

        const config = {
            reference: (new Date()).getTime().toString(),
            email: this.props.userData.student_email,
            amount: this.state.amountToPay * 100,
            publicKey: serverStatus === 'Dev' ? Test_key : Live_key,
            metadata: {
                student_id: this.props.userData.student_id,
                StudentName: `${this.props.userData.student_first} ${this.props.userData.student_middle} ${this.props.userData.student_surname}`,
                student_name: this.props.userData.student_name,
                CartItems: this.state.cartItems,
                Course: this.props.userData.course_name.toUpperCase(),
                student_level: this.props.userData.student_level,
                student_semester: this.props.userData.student_semester,
                student_first: this.props.userData.student_first,
                student_middle: this.props.userData.student_middle,
                student_surname: this.props.userData.student_surname,
                payment_method: 'Paystack',
                outstanding: outstanding,
                amount_paid: amount_paid,
                //sch sem
                school_semester: this.props.userData.app_type === 'student' ? this.state.schoolSemester : this.props.userData.admission_semester,
                app_type: this.props.userData.app_type === 'student' ? 'Returning Student' : 'New Student',
                transaction_date: this.formatDate(new Date()),
                amount_expected: amount_expected,
                currentWallet: this.state.currentWallet,
                minimumAmountAllowForRegistration: this.state.minimumAmountAllowForRegistration,
                payment_for: "Baze",
                custom_fields: [
                    {
                        display_name: "Student ID",
                        variable_name: "Student ID",
                        value: this.props.userData.student_id
                    },
                    {
                        display_name: "Student Name",
                        variable_name: "Student Name",
                        value: `${this.props.userData.student_first} ${this.props.userData.student_middle} ${this.props.userData.student_surname}`
                    },
                    {
                        display_name: "Course",
                        variable_name: "Course",
                        value: this.props.userData.course_name.toUpperCase()
                    },
                    {
                        display_name: "Level",
                        variable_name: "Semester",
                        value: this.props.userData.student_level
                    },
                    {
                        display_name: "Semester",
                        variable_name: "Semester",
                        value: this.props.userData.student_semester
                    },
                    {
                        display_name: "Payment Semester",
                        variable_name: "Payment Semester",
                        value: this.state.schoolSemester
                    },
                    ...dynamicCartItems,
                    {
                        display_name: "Payment For",
                        variable_name: "Payment For",
                        value: "Baze"
                    }
                ]
            }
        };
        //pk_test_4afdc4ff65ce2328e5dd6a678f78103e0fe32c42
        //pk_live_12317e78152cbe1f4fa00ce79b99f497c8e2b989

        const handleSuccess = (reference) => {

            if (reference.status === 'success' && reference.message === "Approved") {
                this.setState({
                    isPaymentChecker: false,
                    isProcessing: true,
                    paymentReady: false,
                    processingPayment: true
                });

                const amount_expected = this.state.amountExpected;
                const amount_paid = this.state.amountToPay;
                let outstanding;

                outstanding = amount_expected - amount_paid

                const sendData = {
                    reference: reference.reference,
                    status: reference.status,
                    payment_id: reference.trxref,
                    student_id: this.props.userData.student_id,
                    student_name: this.props.userData.student_name,
                    student_first: this.props.userData.student_first,
                    student_middle: this.props.userData.student_middle,
                    student_surname: this.props.userData.student_surname,
                    student_level: this.props.userData.student_level,
                    student_semester: this.props.userData.student_semester,
                    course_id: this.props.userData.course_id,
                    description: `Paystack payment ${reference.reference}`,
                    amount_paid: amount_paid,
                    school_semester: this.state.schoolSemester,
                    app_type: this.props.userData.app_type === 'student' ? 'Returning Student' : 'New Student',
                    transaction_date: this.formatDate(new Date()),
                    amount_expected: amount_expected,
                    payment_method: 'Paystack',
                    outstanding: outstanding
                }

                this.processStudentPayment(sendData);
            }
        };

        const handleClose = () => {
            // implementation for  whatever you want to do when the Paystack dialog closed.
            this.setState({
                isPaymentChecker: false,
                isProcessing: false,
                paymentReady: false
            })
        };

        const componentProps = {
            ...config,
            text: 'Pay Now',
            onSuccess: (reference) => handleSuccess(reference),
            onClose: handleClose
        };

        this.setState({
            payStackConfig: componentProps,
            paymentReady: true,
        });

        this.setState({
            isProcessing: true,
            processingMessage: "Click on Pay Now to proceed"
        });

    }

    async processStudentPayment(paymentDetails) {
        this.setState({
            processingMessage: "Processing your payment. Please wait!",
            processingMessage2: "please wait"

        });
        const payment_details = paymentDetails;

        this.getWalletData();
        setTimeout(() => {
            this.setState({ processingMessage2: "", })
        }, 1000);

        this.setState({
            processingMessage: "Payment posted successful. Please wait while the system redirects you to receipt page",
            isPaymentComplete: true,
            isPaymentCompleteID: `/payment-receipt/${payment_details.reference}`
        });
        setTimeout(() => {
            window.location.href = `#/payment-receipt/${payment_details.reference}`
        }, 1500);

    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
        } else {
            return '--'
        }
    };

    handleSemester = (e) => {
        this.setState({
            schoolSemester: e.target.value
        })
    }

    render() {
        let total_cart_amount = this.state.currentWallet < 0 ? this.state.currentWallet : 0;
        return (
            <div>
                {
                    this.state.processingMessage2 !== "" ?
                        <ProcessingLoader />
                        :
                        <div>

                            <Header />
                            {
                                this.state.isLoading
                                    ?
                                    <div className="js-swiper-preloader swiper-preloader">
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <main id="content" role="main">

                                        <div className="container content-space-t-3 content-space-t-lg-4">
                                            <div className="text-center mb-7">
                                                <h1 className="display-6">Pay Now</h1>
                                            </div>

                                            <div className="container content-space-1">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {
                                                            this.props.userData.app_type === 'student' ?
                                                                <div className="alert alert-warning">
                                                                    The minimum tuition amount allowed before registration is {this.currencyConverter(this.state.minimumAmountAllowForRegistration)}
                                                                </div> : ''
                                                        }
                                                        <div className="position-relative">
                                                            <div className="card card-lg">

                                                                <div className="card-body">
                                                                    <h4 className="mb-4 text-center">Payment Form</h4>
                                                                    <ul className="nav nav-pills mb-3" id="pills-tab"
                                                                        role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link active" id="pills-home-tab"
                                                                                data-bs-toggle="pill"
                                                                                data-bs-target="#pills-home" type="button"
                                                                                role="tab" aria-controls="pills-home"
                                                                                aria-selected="true">Tuition Fee
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link" id="pills-profile-tab"
                                                                                data-bs-toggle="pill"
                                                                                data-bs-target="#pills-profile" type="button"
                                                                                role="tab" aria-controls="pills-profile"
                                                                                aria-selected="false">Other Fees
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="tab-content" id="pills-tabContent">
                                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>S/N</th>
                                                                                            <th>Description</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>1</td>
                                                                                            <td>
                                                                                                {this.state.CourseClass === "Years" ? "Semester Tuition" : "Tuition Fee"}
                                                                                            </td>
                                                                                            <td>{this.currencyConverter(this.state.tuitionFee)}</td>
                                                                                            <td>
                                                                                                <div className="form-check">
                                                                                                    <input className="form-check-input"
                                                                                                        type="radio"
                                                                                                        value={this.state.tuitionFee}
                                                                                                        onClick={this.handleTuitionChange}
                                                                                                        name="tuitionRadio"
                                                                                                        id="Semester Tuition" />
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-striped">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>S/N</th>
                                                                                            <th>Description</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            this.state.otherFees.length > 0 ?
                                                                                                this.state.otherFees.map((item, index) => {

                                                                                                    if (item.IsRequired === 0) {
                                                                                                        return (
                                                                                                            <tr key={index}>
                                                                                                                <td>{index + 1}</td>
                                                                                                                <td>{item.Title}</td>
                                                                                                                <td>{this.currencyConverter(item.Amount)}</td>
                                                                                                                <td>
                                                                                                                    <div className="form-check">
                                                                                                                        <input className="form-check-input"
                                                                                                                            type="checkbox"
                                                                                                                            value={item.Amount}
                                                                                                                            onChange={this.handleOtherFeeChange}
                                                                                                                            name="otherFeeCheckbox"
                                                                                                                            id={item.Title} />
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return false;
                                                                                                    }
                                                                                                }) :
                                                                                                <div className="alert alert-warning">NO OTHER FEES</div>
                                                                                        }

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 mb-10">
                                                        <div className="position-relative">
                                                            <div className="card card-lg">
                                                                <div className="card-body">
                                                                    <h4 className="mb-4 text-center">Payment Items</h4>
                                                                    {
                                                                        this.state.cartItems.length > 0
                                                                            ?
                                                                            <div className="mb-5">
                                                                                <div className="table-responsive-sm">
                                                                                    <table className="table table-lg table-striped table-borderless">
                                                                                        <thead>
                                                                                            <tr className="table-align-middle">
                                                                                                <th scope="col">Item</th>
                                                                                                <th scope="col" className="table-text-center h5">Amount</th>
                                                                                            </tr>
                                                                                        </thead>

                                                                                        <tbody>
                                                                                            {
                                                                                                this.state.cartItems.map((item, index) => {
                                                                                                    total_cart_amount = total_cart_amount + parseFloat(item.item_amount);
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <th scope="row">
                                                                                                                {item.item_name}
                                                                                                                {
                                                                                                                    item.item_name.includes('Hostel') ? <><br /><p className="badge bg-danger">Hostel can only be allocated to you if you pay tuition!</p></> : ''
                                                                                                                }
                                                                                                            </th>
                                                                                                            <td className="table-text-center">
                                                                                                                {this.currencyConverter(item.item_amount)}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                            {/*<tr>*/}
                                                                                            {/*    <th scope="row">{this.state.currentWallet > 0 ? 'Outstanding' : 'Current Wallet'}</th>*/}
                                                                                            {/*    <td className="table-text-center">*/}
                                                                                            {/*        {this.currencyConverter(this.state.currentWallet)}*/}
                                                                                            {/*    </td>*/}
                                                                                            {/*</tr>*/}
                                                                                            <tr>
                                                                                                <th scope="row"><b>Item(s) Total</b></th>
                                                                                                <td className="table-text-center">
                                                                                                    <b>{this.currencyConverter(total_cart_amount)}</b>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <th scope="row">
                                                                                                    {
                                                                                                        total_cart_amount > 0 ? <b>Amount Due</b> : <b>Wallet Balance</b>
                                                                                                    }

                                                                                                </th>
                                                                                                <td className="table-text-center">
                                                                                                    <b>{this.currencyConverter(total_cart_amount)}</b>
                                                                                                    <input type="hidden" id="TotalAmountDue" value={total_cart_amount} />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="table-text-center" colSpan={2}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-sm-12 col-md-6">
                                                                                                            <b>School Semester</b>
                                                                                                        </div>
                                                                                                        <div className="col-sm-12 col-md-6">
                                                                                                            <select name="schoolSemester" id="schoolSemester" className="form-control" onChange={this.handleSemester}
                                                                                                                value={this.state.schoolSemester}>
                                                                                                                <option value="">Select Payment Semester</option>
                                                                                                                <option value="24C">September 2024 (24C)</option>

                                                                                                                {
                                                                                                                    this.props.userData.userType !== "new" &&
                                                                                                                    <>
                                                                                                                        <option value="24C">September 2024 (24C)</option>
                                                                                                                        <option value="24B">May 2024 (24B)</option>
                                                                                                                        <option value="24A">January 2024 (24A)</option>
                                                                                                                        <option value="23C">September 2023 (23C)</option>
                                                                                                                        <option value="23B">May 2023 (23B)</option>
                                                                                                                        <option value="23A">January 2023 (23A)</option>
                                                                                                                    </>
                                                                                                                }

                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {
                                                                                                total_cart_amount > 0 && this.state.schoolSemester !== '' ?
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className="table-text-center" colSpan={2}>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-12 col-md-6">
                                                                                                                        <b>Amount You're Paying</b>
                                                                                                                    </div>
                                                                                                                    <div className="col-sm-12 col-md-6">
                                                                                                                        <input type="number" className="form-control" id="amount_to_pay" onChange={this.handleOnPaymentChange} placeholder="Enter the amount you want to pay" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                    : <></>
                                                                                            }

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>

                                                                                {
                                                                                    this.state.isProcessing ?
                                                                                        <div className="d-grid mt-2">
                                                                                            <div className="alert alert-info">
                                                                                                {this.state.processingMessage}
                                                                                            </div>
                                                                                        </div>
                                                                                        : ''
                                                                                }

                                                                                {
                                                                                    this.state.isPaymentComplete ?
                                                                                        <Link to={this.state.isPaymentCompleteID} className="btn btn-secondary">
                                                                                            Print Receipt <i className="bi-printer" />
                                                                                        </Link> : ''
                                                                                }

                                                                                {
                                                                                    this.state.processingPayment === false ?
                                                                                        <div className="d-grid mt-2">
                                                                                            {
                                                                                                total_cart_amount > 0 ?
                                                                                                    this.state.paymentAmount > 0 ?
                                                                                                        <PaystackButton id="PayWithPayStackBtn" className="btn btn-primary btn-lg" {...this.state.payStackConfig} /> : ''
                                                                                                    // this.state.paymentReady === true ?
                                                                                                    //     <PaystackButton id="PayWithPayStackBtn" className="btn btn-primary btn-lg" {...this.state.payStackConfig} />
                                                                                                    //     : <button type="submit" className="btn btn-primary btn-lg" onClick={this.payNow}>Validate Payment</button>
                                                                                                    : <button type="submit" className="btn btn-primary btn-lg" onClick={this.payFromWallet}>Pay From Wallet</button>
                                                                                            }

                                                                                        </div>
                                                                                        : ''
                                                                                }


                                                                                <figure className="position-absolute bottom-0 end-0 d-none d-md-block me-n5 mb-n5" style={{ width: "10rem" }}>
                                                                                    <img className="img-fluid" src="./assets/images/plane.svg"
                                                                                        alt="Image Description" />
                                                                                </figure>
                                                                            </div>
                                                                            :
                                                                            <div className="alert alert-warning">Select Item from the Payment Form above to proceed</div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </main>
                            }

                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails,
        tuitionFee: state.courseFeeData,
        otherFees: state.otherFeeData,
        minimumAmountAllowForRegistration: state.minAmountData,
        scholarship: state.scholarshipData,
        wallet: state.walletData,
        medicals: state.medicalsData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnWalletDataDetails: (p) => {
            dispatch(setWalletData(p));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayNow);
